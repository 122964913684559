import React from 'react';
import styled from 'styled-components';

import ProductWrapper from 'parts/product-columns/ProductWrapper';
import LazyImage from 'components/LazyImage';
import DefaultImage from 'images/placeholders/default-image-post.jpg';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import { getMonthlyPrice } from 'components/products/Price';
import { priceFormatting } from 'libs/content';

const ImageWrap = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 20px;
	.lazy-image {
		position: relative;
		width: 100%;
		height: 250px;
		overflow: hidden;
		.lazy-image__main {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: auto;
			height: auto;
			width: auto;
			max-height: 100%;
			max-width: 100%;
		}
	}
`;

const PriceLarge = styled.p`
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	margin: 0;
`;

const PriceSmall = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	&.cheapest-price {
		color: ${p => p.theme.colors.grey700};
	}
`;

/**
 * Represents a product item
 * @param {object} product - The product properties
 * @param {string} headinglevel - The heading level for the product title
 * @param {...object} props - The properties for the component
 */
export default function ProductItem({
	product,
	headinglevel = 'h3',
	...props
}) {
	// Temporary stuff
	product = {
		...product,
		title: product?.title || product?.tittel,
		smallTitle: product?.smallTitle || product?.undertittel,
		desc: product?.desc || product?.kortBeskrivelse,
		price: product?.productPrice || product?.price || product?.pris,
		campaignPrice: product?.campaignPrice || product?.kampanjepris,
		cheapestPriceLast30days: product?.cheapestPriceLast30days,
		paymentMethods: product?.paymentMethods,
		image:
			(product?.images?.length > 0 && product?.images[0]) ||
			product?.bilde,
	};

	const productTitle =
		product?.title && product?.smallTitle
			? `${product?.title} - ${product.smallTitle}`
			: product?.title;

	return (
		<ProductWrapper
			id={product?.id}
			to={`/produkter/${product.slug}`}
			className="contentfulinnholdprodukt"
			customBadge={product.badge}
			campaign={product?.campaignPrice}
			perrow={props?.perrow || 3}
			{...product}
			header={
				<>
					<ImageWrap>
						{product?.image ? (
							<LazyImage
								{...product?.image}
								alt={
									product?.image?.description ||
									product?.image?.title ||
									productTitle
								}
							/>
						) : (
							<img src={DefaultImage} alt={productTitle} />
						)}
					</ImageWrap>

					<TitleAndText
						title={product?.smallTitle}
						text={product?.title}
						headinglevel={headinglevel}
						nested={true}
					/>
				</>
			}>
			{product?.price && (
				<PriceLarge>
					{(product?.paymentMethods?.includes('Avbetaling (3 år)')
						? `${getMonthlyPrice(product)} kr/mnd`
						: `${product?.price} kr`) || ''}
				</PriceLarge>
			)}
			{product?.price &&
				product?.paymentMethods?.includes('Avbetaling (3 år)') && (
					<PriceSmall>
						Totalpris:{' '}
						{priceFormatting(product?.price, 'NOK', 'en-GB', false)}{' '}
						kr
					</PriceSmall>
				)}
			{product?.cheapestPriceLast30days && (
				<PriceSmall className="cheapest-price">
					{product?.cheapestPriceLast30days}
				</PriceSmall>
			)}
		</ProductWrapper>
	);
}
