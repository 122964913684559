import React, { useEffect } from 'react';

import { track } from 'context/AnalyticsProvider';
import { getComponentSettings, getUrl } from 'libs/content';
import MaxWidth from 'layouts/max-width';
import { GridWrapper } from 'parts/product-columns/ProductWrapper';
import ProductItem from './ProductItem';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Spacing from 'layouts/Spacing';
import BasicContent from 'parts/basic-content/BasicContent';

/**
 * Represents a grid of products
 * @param {...object} props - The properties for the component
 */
export default function ProductGrid({ ...props }) {
	const componentSettings = getComponentSettings({
		settings: props?.settings,
	});

	useEffect(() => {
		if (!props?.products?.length > 0) return;

		const productsArray = [];
		props?.products.forEach((p, i) => {
			productsArray.push({
				product_id: p.id,
				name: p.title,
				price: p?.campaignPrice || p?.price,
				position: i + 1,
				category: (p?.group?.name && p?.group?.name) || 'Produkter',
				url: process.env.GATSBY_URL + getUrl(p?.slug, 'produkt'),
				image_url: p?.image?.file?.url,
			});
		});

		track('Product List Viewed', {
			list_id: props?.id,
			category: 'Produkter',
			products: productsArray,
			index: process.env.GATSBY_ALGOLIA_INDEX_NAME + '_products',
			queryID: productsArray?.length > 0 && productsArray[0]?.product_id,
		});
		//eslint-disable-next-line
	}, [props.id]);

	if (!props?.products?.length > 0) return;

	return (
		<>
			<Spacing
				className="component__product-grid"
				data-cy="component__product-grid"
				{...props}
				spacing={
					(componentSettings?.archivelink === 'true' && {
						top: 'none',
						bottom: 'large',
					}) ||
					props?.spacing
				}>
				<MaxWidth>
					{componentSettings?.hidetitle !== 'true' && (
						<TitleAndText
							title={props?.title}
							text={props?.intro}
							headinglevel={props?.headinglevel || 'h2'}
							settings={props?.settings}
							nested={true}
						/>
					)}
					<GridWrapper
						id="product-grid"
						perrow={3}
						total={props?.products?.length}>
						{props?.products?.map((product, i) => {
							return (
								<ProductItem
									key={product?.id + '-' + i}
									product={product}
									headinglevel="h3"
									perrow={3}
								/>
							);
						})}
					</GridWrapper>
				</MaxWidth>
			</Spacing>
			{componentSettings?.archivelink === 'true' && (
				<section
					key="archive"
					id="ContentfulKomponentInnhold-1"
					data-cy="ContentfulKomponentInnhold"
					className="ContentfulKomponentInnhold">
					<BasicContent
						title="Leter du etter et annet produkt?"
						content="Vi er opptatt av alt skal være så enkelt som mulig for deg som kunde. Derfor har vi samlet noen av de mest populære produktene og tjenestene på nettsiden vår, med priser som inkluderer montering."
						headinglevel="h3"
						settings={
							(props?.settings?.includes('Midtstilt tekst') &&
								props?.settings) ||
							[]
						}
						buttons={[
							{
								to: '/produkter',
								text: 'Se alle produkter',
								outlined: 'true',
							},
						]}
					/>
				</section>
			)}
		</>
	);
}
